import '../Smallcard/smallcard.css';
import '../../bootstrap/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { response } from 'express';
// import { response } from 'express';

import {useEffect, useState} from "react";

function Smallcard(){
    const [users, setUsers] = useState({});

    useEffect(() => {
        fetch('https://abiwrite.com/db/news.php')
        .then((response) => response.json())
            .then(data => {
                setUsers(data)
                console.log(data)
            })
          
        .catch(err => console.log(err))
    }, [])

    const outputData = Array.from(users);
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage =3;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = outputData.slice(firstIndex, lastIndex);
    const npages = Math.ceil(outputData.length / recordPerPage);
    const numbers = [Array(npages + 1).keys()].slice(5);

 
    return(
        <div className="card" id='cc'>

            {
                records.map(
                    (u, k) => (
                        <a  href={`https://abiwrite.com/single/?id=${u.id}`} key = {u.id}  alt='' >
                            <div className="d-sm-inline-block small-card" >
                                
                                <img src={`https://abiwrite.com/admin/images/${u.image}`} alt="card" />
                                    <h3>{u.heading}</h3>
                                <div className="cat">
                                    {u.categories}
                                </div>
                                

                            </div>
                        </a>

                        
                    )
                )

            }
            <nav id='sc'>
                <ul className='pagination' id='paginationbtn'>
                    <li className='page-item'>
                        <a href='#cc' className='page-link' onClick={prevPage}>Prev</a>
                    </li>
                    {
                       numbers.map((n, i) => (
                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                            <a href='#cc' className='page-link' onClick={changePage(n)}>{n}</a>
                        </li>
                       )

                       ) 
                    }
                    <li className='page-item'>
                        <a href='#cc' className='page-link' onClick={nextPage}>Next</a>
                    </li>
                </ul>
            </nav>
                   
        </div>
    )
    function prevPage(){
        if(currentPage !== firstIndex){
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id){
        setCurrentPage(id)
    }
    function nextPage(){
        if(currentPage !== lastIndex){
            setCurrentPage(currentPage + 1)
        }
    }
}
export default Smallcard;