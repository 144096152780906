import './Horizontalcard.css';
import '../../bootstrap/css/bootstrap.min.css';
import {useEffect, useState} from "react";

function Horizontalcard(){
    const [users, setUsers] = useState({});

  useEffect(() => {
      fetch('https://abiwrite.com/db/all.php')
      .then((response) => response.json())
          .then(data => {
             setUsers(data)
              console.log(data)
          })
      .catch(err => console.log(err))
  }, []);
  const outputData = Array.from(users);

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage =8;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = outputData.slice(firstIndex, lastIndex);
  const npages = Math.ceil(outputData.length / recordPerPage);
  const numbers = [Array(npages + 1).keys()].slice(1);
    return(
        <div id='newpost'>
                {
                    records.map(
                        (u, k) => (
                            <a href={`https://abiwrite.com/single/?id=${u.id}`} key = {u.id} alt='' >
                                <div className="hc-container">
                                    <img src={`http://abiwrite.com/admin/images/${u.image}`} alt="hc" />
                                    <h3>{u.heading}</h3>
                                </div>
                            </a>                        
                        )
                    )
                    

                }
            <nav>
                <ul className='pagination' id='paginationbtn'>
                    <li className='page-item'>
                        <a href='#newpost' className='page-link' onClick={prevPage}>Prev</a>
                    </li>
                    {
                    numbers.map((n, i) => (
                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                            <a href='#newpost' className='page-link' onClick={chagePage(n)}>{n}</a>
                        </li>
                    )

                    ) 
                    }
                    <li className='page-item'>
                        <a href='#newpost' className='page-link' onClick={nextPage}>Next</a>
                    </li>
                </ul>
            </nav>
        </div>

          

    )
    function prevPage(){
        if(currentPage !== firstIndex){
            setCurrentPage(currentPage - 1)
        }
    }
    function chagePage(id){
        setCurrentPage(id)
    }
    function nextPage(){
        if(currentPage !== lastIndex){
            setCurrentPage(currentPage + 1)
        }
    }
}
export default Horizontalcard;